<template>
  <div>
    <div class="card" v-if="totalLength > 0">
      <div class="card-content">
        <div class="level">
          <div class="level-left">
            <h4 class="title is-6">Tukang Yang Sudah Dipilih: </h4>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth" v-if="projectWorker.worker_list !== null">
              <tr>
                <th>Nama Tukang</th>
                <th>Skill</th>
                <th>Tanggal Pengerjaan</th>
              </tr>
              <tr v-for="(value, index) in sortListWorker" :key="'A'+ index">
                <td>
                  <div class="media">
                    <img :src="value.worker_image" class="media-left" style="width: 25px;" />
                    <div class="media-content">
                      <h3>{{ value.worker_name }}</h3>
                    </div>
                  </div>
                </td>
                <td> {{ value.skill }} </td>
                <td> {{ convDate(value.date_sesi) }} </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <section v-if="totalLength === 0" class="section">
      <div class="content has-text-grey has-text-centered">
        <template>
          <p>
            <b-icon icon="emoticon-sad" size="is-large"/>
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkerSign',
  props: ['dataProject'],
  data () {
    return {
      isLoading: false,
      search: null,
      workerData: [],
      workerSelected: null,
      skillWorker: null,
      selectedSkillWorker: [],
      labelPosition: 'on-border',
      skills: [],
      currentSkill: null,
      selectedS: null,
      hideSkills: false,
      tglProject: null,
      selectedTglproj: null,
      workerId: {},
      projectWorker: null,
      // workerList: null,
      totalLength: 0
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    sortListWorker () {
      let listWorker = this.projectWorker.worker_list
      listWorker = listWorker.sort((a, b) => {
        const fa = a.date_sesi.toLowerCase()
        const fb = b.date_sesi.toLowerCase()
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })

      return listWorker
    }
  },
  watch: {},
  methods: {
    convDate (val) {
      return moment(val).format('dddd, DD MMMM YYYY')
    },
    statusProj () {
      const stats = this.dataProject.status
      if (stats === 'Active' || stats === 'Completed' || stats === 'Cancelled') {
        return 'is-hidden'
      }
    },
    clearData () {
      this.workerSelected = null
      this.currentSkill = null
      this.tglProject = null
    },
    tglIsSelected (event) {
      this.selectedTglproj = event
    },
    skillIsSelected (event) {
      this.selectedS = event
    },
    backToSearch () {
      this.workerSelected = null
    },
    getResultValue (val) {
      if (val !== null) {
        return val.name
      }
    },
    handleResultValue (val) {
      let totalW = 0
      this.projectWorker.schedule.forEach((rw, _idx) => {
        rw.detail_sesi.forEach((rows, _idxs) => {
          if (rows.dataWorker.total_worker !== null) {
            totalW += rows.dataWorker.total_worker
          }
        })
      })

      var lengthWorker = this.projectWorker.worker_list.length
      for (let i = 0; i < lengthWorker; i++) {
        if (lengthWorker >= totalW) {
          this.workerSelected = null
          this.$refs.autocomplete.value = ''
          this.$buefy.toast.open({
            duration: 2000,
            message: 'Worker Sudah Dipilih Melebihi batas!',
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false
        } else if (val !== null && val.id !== this.projectWorker.worker_list[i].worker_id) {
          this.workerSelected = val
        }
      }

      this.workerSelected = val
    },
    cariSkill () {
      axios.get(this.url + '/api/v1/vendor/skill?page=1&limit=1000')
        .then((cb) => {
          this.skills = cb.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectedAll (val) {
      const data = {
        worker_id: val.id,
        skill_id: this.currentSkill.id,
        worker_image: val.image_uri,
        project_id: this.dataProject.id,
        sesi_id: this.tglProject.sesi_id,
        date_sesi: this.tglProject.date_sesi
      }

      axios.post(this.url + '/api/v1/vendor/worker-assign', data)
        .then(() => {
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Simpan berhasil !...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearData()
          this.$refs.autocomplete.value = ''
          this.getWorkerAssign(this.$route.params.projectId)
          this.loading = false
        }).catch((err) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: err.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    removeCurrentSkill (v) {
      this.$buefy.dialog.confirm({
        message: 'Hapus tukang terpilih ?',
        onConfirm: () => {
          axios.delete(this.url + '/api/v1/vendor/worker-assign/' + v + '/remove')
            .then((cb) => {
              this.clearData()
              this.$refs.autocomplete.value = ''
              this.getWorkerAssign(this.$route.params.projectId)
              this.$buefy.toast.open({
                duration: 2000,
                message: 'Hapus tukang berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
              this.loading = false
            }).catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus tukang',
                position: 'is-bottom',
                type: 'is-danger'
              })
              this.loading = false
            })
        }
      })
    },
    async getWorkerAssign (id) {
      await axios.get(`${this.url}/api/v1/vendor/project_detail_sesi?project_id=${id}`)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.projectWorker = cb.data.data[0]
            this.totalLength = this.projectWorker.worker_list.length
          }
        })
    }
  },
  created () {
    // this.cariWorker()
    this.cariSkill()
    this.getWorkerAssign(this.$route.params.projectId)
  }
}
</script>

<style lang="scss" scoped>
.is-hidden {
  visibility: hidden;
}

.is-show {
  visibility: visible;
}

.borderCard {
  border: 1px solid rgb(192,192,192);
}

</style>

<!--
  button rating akan muncul perhari dengan validasi datetime atau dengan status project (jaga jika cron tidak jalan)
  ada kondisi dimana button akan disabled setelah worker sudah diberikan rating pada tanggal pengerjaan yang sudah diberikan rating.
  pada saat button rating click, muncul modal dengan form rating dengan data worker yang akan dirating.
-->
