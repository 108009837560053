<template>
  <div>
    <b-table :data="storeAttendances">
      <b-table-column field="id" label="Id HomeCare" width="120" centered numeric>
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          #{{ props.row.idHomecare }}
        </template>
      </b-table-column>

      <b-table-column field="id" label="Worker ID" width="100" centered numeric>
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          {{ props.row.workerId }}
        </template>
      </b-table-column>

      <b-table-column field="user.first_name" label="Worker Profile">
        <template v-slot:header="{ column }">
          <b-tooltip :label="column.label" append-to-body dashed>
            {{ column.label }}
          </b-tooltip>
        </template>
        <template v-slot="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-32x32">
                <img :src=props.row.profileImage>
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>{{ props.row.workerName }}</strong>
                  <br>
                  {{ props.row.skillWorker }}
                  <br>
                  {{ props.row.jabatan }}
                </p>
              </div>
            </div>
          </article>
        </template>
      </b-table-column>

      <b-table-column field="date_attendance" centered label="Attendance Date">
        <template v-slot="props">
          {{ props.row.attendanceDate }}
        </template>
      </b-table-column>

      <b-table-column field="absen" label="Attendance Status" centered v-slot="props">
        <div class="columns is-text-centered">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column">
                <span class="is-size-6" :class="{'tag is-danger': props.row.statusAbsen === 0, 'tag is-info': props.row.statusAbsen === 1 || 2}">
                  {{ props.row.attendanceStatus }}
                </span>
                <span>
                  <section v-if="props.row.imageAbsen !== null">
                    <img class="imgAbsen" :src="props.row.imageAbsen"/>
                  </section>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-table-column>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AttandanceWorker',
  data () {
    return {
      isLoading: false,
      modalImageAbsen: false,
      workers: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      attendances: 'attendance/getAttendances'
    }),
    nowDate () {
      const date = moment()
      const dateNow = date.format('YYYY-MM-DD')
      return dateNow
    },
    storeAttendances () {
      const dataWorkers = []
      if (this.attendances !== null) {
        this.attendances.attendance.forEach((a) => {
          a.workers.forEach((t) => {
            let absenStat
            if (t.attendance_status === 0) {
              absenStat = 'Tidak Hadir'
            }

            if (t.attendance_status === 1) {
              absenStat = 'Absen QR'
            }

            if (t.attendance_status === 2) {
              absenStat = 'Absen Selfie'
            }

            dataWorkers.push({
              idHomecare: this.attendances.project_id_homecare,
              attendanceDate: a.attendance_date,
              workerId: t.worker_id,
              workerName: t.worker_name,
              profileImage: t.worker_profile_pic,
              skillWorker: t.skill_name,
              statusAbsen: t.attendance_status,
              imageAbsen: t.attendance_image,
              attendanceStatus: absenStat
            })
          })
        })
      }

      return dataWorkers
    }
  },
  methods: {
    seeImageAbsen () {
      this.viewmodal(1)
    },
    viewmodal (newVal) {
      if (newVal === 1) {
        this.modalImageAbsen = true
      }
    }
  },
  mounted () {},
  created () {}
}
</script>

<style lang="scss" scoped>
.imgAbsen {
  margin-top: 1rem;
  height: auto;
  width: 40%;
  object-fit: cover;
  vertical-align: bottom;
}
</style>
